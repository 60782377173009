import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import BackgroundCloud1 from "../components/background/BackgroundCloud1"
import BackgroundCloud2 from "../components/background/BackgroundCloud2"
import ContactUs from "../components/ContactUs"

const Page = () => (
  <Layout>
    <PageHeader title="Cloud Build &amp; DevOps Tools" subtitle="Our experts  provide assistance in the deployment and maintenance of cloud environments" />
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
        <div className="col-lg-6 text-center">
        <div className="img-wrap box-bottom-right width-100  height-400">
              <BackgroundCloud2 />
            </div>
          </div>
          <div className="col-lg-6 mt-5">
            <div className="col-limit-3 mt-4">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">Cloud Build</h2>
            </div>
            <p className="paragraph-3">
              VoerEir provides a comprehensive way to build, verify and deploy
              different cloud options and provide end user a secure, reliable,
              quick private cloud option.
            </p>
            <p className="paragraph-3">
            Cloud Build helps you executes your builds different infrastructure. Cloud Build can import source code from different repositories, execute a build to your specifications, and produce artifacts such on your private cloud options.
            </p>
          </div>

        </div>
      </div>
    </section>
    <section className="section-padding-top">
      <div className="container">
        <div className="row row-mobile-reverse">
          <div className="col-lg-6">
            <div className="col-limit-3 mt-5">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">DevOps Tools</h2>
            </div>
            <p className="paragraph-3">
              VoerEir offers end to end DevOps solutions customised for your
              business. We provide DevOps consulting and we help in building an
              end-to-end DevOps tool chain. Our DevOps offerings includes DevOps
              Consulting, Continuous Integration and Deployment, Tests
              integration and Continuous Delivery.
            </p>
          </div>
          <div className="col-lg-6 text-center">
            <div className="img-wrap box-bottom-left width-100  height-400">
              <BackgroundCloud1 />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ContactUs />
  </Layout>
)

export default Page
